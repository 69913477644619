<template>
  <div class="app-main-content">
    <card title="项目协作">
      <template #tools>
        <div class="line" style="margin-left: -3px">
          <div class="count">
            共
            <span>{{ projectList.length }}</span>
            个
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span class="label">排序</span>
            <a-select @change="getList" class="sort-select" placeholder="请选择"
                      v-model="params.orderby">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="schedule_end_date desc">计划完成倒序</a-select-option>
              <a-select-option value="schedule_end_date asc">计划完成正序</a-select-option>
              <a-select-option value="business_status desc">项目状态倒序</a-select-option>
              <a-select-option value="business_status asc">项目状态正序</a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="params.keywords" placeholder="编号、名称">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <a-button class="search" @click="getList">查询</a-button>
        </div>
      </template>
      <div class="scrollY" style="min-width: 1400px;max-width: 1600px;">
        <a-table :data-source="projectList" bordered :pagination="false" rowKey="oid">
          <a-table-column title="项目编号" data-index="project_code" width="110px">
            <template slot-scope="scope, row">
              <edp-input
                style="width: 100%"
                size="mini"
                v-model="row.project_code"
                :db="dbs.prjCore"
                field-name="project_code"
                :oid="row.oid"
                :prj-oid="row.oid"
              ></edp-input>
            </template>
          </a-table-column>
          <a-table-column title="项目名称" data-index="project_name">
            <template slot-scope="scope, row, $index">
              <div class="edp-table-name">
                <!--                <a-input ref="table-name" v-if="row.editName" v-model="row.project_name"-->
                <!--                         @blur="fieldUpdate(row, $index)"></a-input>-->
                <edp-input
                  ref="table-name"
                  v-if="row.editName"
                  :db="dbs.prjCore"
                  :prj-oid="row.oid"
                  :oid="row.oid"
                  v-model="row.project_name"
                  field-name="project_name"
                  @change="getList"
                ></edp-input>
                <router-link style="color: #08f" v-else :to="{path: '/supplier', query: {prj_oid: row.oid}}">{{
                    scope
                  }}
                </router-link>
                <i v-if="!row.editName" class="iconfont icon-wenbenshuru" @click="clickFoucs($index)"></i>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="项目状态" data-index="business_status" width="110px">
            <template slot-scope="scope, row">
              <edp-select
                :skin="`status ${row.business_status === '100' ? 'yellow' : row.business_status === '500' ? 'green' : row.business_status === '1000' ? 'gray' : ''}`"
                v-model="row.business_status"
                :db="dbs.prjCore"
                field-name="business_status"
                :oid="row.oid"
                :prj-oid="row.oid"
                :options="sort_project_bstatus"
                options-type="cat"
              ></edp-select>
            </template>
          </a-table-column>
          <a-table-column title="项目负责人" width="140px">
            <template slot-scope="row">
              <edp-select
                style="width: 100%;"
                v-model="row.project_admin"
                :db="dbs.prjCore"
                field-name="project_admin"
                :oid="row.oid"
                :prj-oid="row.oid"
                :options="getAccountOption(row.members ?? [])"
              >
              </edp-select>
            </template>
          </a-table-column>
          <a-table-column title="项目年份" data-index="project_year" width="130px">
            <template slot-scope="scope, row">
              <edp-year-picker
                style="width: 100%;"
                v-model="row.project_year"
                :db="dbs.prjCore"
                field-name="project_year"
                :prj-oid="row.oid"
                :oid="row.oid"
              />
            </template>
          </a-table-column>
          <a-table-column title="计划结束" data-index="schedule_end_date" width="140px">
            <template slot-scope="scope, row">
              <edp-date-picker
                style="width: 100%;"
                v-model="row.schedule_end_date"
                :db="dbs.prjCore"
                field-name="schedule_end_date"
                :prj-oid="row.oid"
                :oid="row.oid"
              ></edp-date-picker>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </card>
  </div>
</template>

<script>
import {fetch} from "@/utils/request.js";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";
import EdpYearPicker from "@/components/e-form/edp-year-picker.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpDatePicker from "@/components/e-form/edp-date-picker.vue";
import catMixin from "@/utils/mixins/catMixin";

export default {
  name: "projectList",
  mixins: [catMixin],
  data() {
    return {
      cat: {
        project_bstatus: []
      },
      dbs: {
        prjCore: new FieldUpdateDb("prj", "prj_core")
      },
      params: {
        keywords: '',
        business_status: null,
        orderby: ''
      },
      projectList: [],
      tempList: [],
      memberSearch: '',
      checked: [],
      newChecked: [],
      options: [],
      allOptions: [],
      accountList: [],
      company_info: {}
    }
  },
  components: {
    EdpDatePicker,
    EdpSelect,
    EdpInput,
    EdpYearPicker,
  },
  computed: {
    sort_project_bstatus() {
      let cloneBstatus = JSON.parse(JSON.stringify(this.cat.project_bstatus))
      return cloneBstatus.sort((a, b) => {
        if (a.cat_name === '已归档') {
          return 1
        } else if (a.cat_name === '已完成' && b.cat_name !== '已归档') {
          return 1
        } else if (a.cat_name === '已暂停' && b.cat_name !== '已完成') {
          return 1
        } else if (a.cat_name === '进行中' && b.cat_name === '未开始') {
          return 1
        } else {
          return -1
        }
      })
    },
    checkedItems() {
      return this.newChecked.map(item => {
        return this.allOptions.find(i => item === i.value)
      })
    },
    searchItems() {
      if (this.memberSearch) {
        return this.options.filter((i) => {
          return i.name.indexOf(this.memberSearch) !== -1 || i.email.indexOf(this.memberSearch) !== -1 || i.company_name.indexOf(this.memberSearch) !== -1
        })
      } else {
        return this.options.filter((item) => {
          return this.company_info.company_oid === item.company_oid
        }).filter((item) => {
          return this.company_info.account_oid !== item.oid
        })
      }
    }
  },
  created() {
    this.company_info = this.storage.getItem('company_info')
  },
  mounted() {
    this.getList()
    this.getTempList()
    this.getMemberList()
  },
  methods: {
    getAccountOption(list) {
      return list.map(item => {
        return {
          value: item.oid,
          label: item.name,
          key: item.oid
        }
      })
    },
    getList() {
      let url = window.mock ? 'http://127.0.0.1:4523/m1/2234408-0-default/prj/retrieve' : '/prj/retrieve'
      fetch('post', url, this.params).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          if (data.length > 0) {
            data = data.map(item => {
              return {
                ...item,
                editName: false
              }
            })
            this.projectList = data
          } else {
            this.projectList = []
          }
        }
      })
    },
    getMemberList() {
      fetch('post', '/cpyaccount/list', {keywords: '', page_size: 999}).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          if (data.length > 0) {
            data.forEach(e => {
              e['value'] = e.oid
            })
            data.sort((a, b) => {
              if (Number(a.seqid) > Number(b.seqid)) {
                return 1
              } else {
                return -1
              }
            })
            this.options = data
            this.allOptions = data
            // console.log(this.options);
          }
        }
      })
    },
    removeChecked(item) {
      console.log(item);
      this.newChecked = this.newChecked.filter(i => i !== item.value) // 已选中删除
      this.checked = this.checked.filter(i => i !== item.value) // 选中删除
    },
    tabsChange() {
      this.getList()
    },
    fieldUpdate(row, index) {
      console.log(row);
      this.projectList[index].editName = false
      this.$updateOnefield('prj', 'prj_core', 'project_name', row.project_name, row.oid, row.oid)
      this.editFolderName(row.project_name, row.oid)
    },
    clickFoucs(index) {
      // console.log(index);
      this.projectList[index].editName = true
      this.$nextTick(() => {
        this.$refs["table-name"].focus()
      })
    },
    deselectMembers(user_oid, row) {
      console.log('取消', user_oid, row);
      fetch('post', '/prj/userlist/delete', {
        prjoid: row.oid,
        user_oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('刪除成功！')
        }
      })
    },
    selectMembers(user_oid, row) {
      console.log('选中', user_oid, row);
      fetch('post', '/prj/userlist/create', {
        prjoid: row.oid,
        user_oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('新增成功！')
        }
      })
    },
    editFolderName(folder_name, prjoid) {
      fetch('post', '/prj/folder/update', {
        folder_name,
        prjoid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
        }
      })
    },
    onChange(checkedList) {
      // console.log(checkedList);
      // console.log(this.searchItems);
      if (checkedList.length > 0) { // 选取有值时
        checkedList.forEach(e1 => {
          if (!this.newChecked.includes(e1)) { // 总选中数组遍历没有的 push进去
            this.newChecked.push(e1)
          }
        })
      }
      this.searchItems.forEach(e1=>{ // 遍历搜索出来的所有项
        if (!checkedList.includes(e1.value)) { // 当前选中的有没有搜索出来的值
          console.log(e1.username);
          let index = this.newChecked.indexOf(e1.value)
          if (index !== -1) this.newChecked.splice(index, 1) // 没有就进行删除
        }
      })
      this.checked = this.newChecked
    },
    getTempList() {
      fetch('post', '/prj/retrieve', {
        keywords: '',
        business_status: null,
        orderby: '',
        is_template: '1'
      }).then((res) => {
        if (res.status === 0) {
          this.tempList = res.data.datas
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .edp-card {
  .edp-card-head-left-tabs {
    margin-left: -12px;
  }
}

.common_detail_body {

  .detail_body_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: content-box;
    //border-bottom: 1px solid #e8e8e8;
    padding: 0 38px 0 20px;
    height: 51px;
    flex-shrink: 0;

    .left {
      display: flex;
      align-items: center;

      .count {
        color: #4D4D4D;
        margin-left: 11px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .ant-dropdown-link {
        width: 32px;
        height: 32px;
        border-radius: 4px 4px 4px 4px;
        background: rgba(243, 243, 243, 1);
        color: #3D3D3D;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
      }
    }
  }

  /deep/ .detail_body_content {
    overflow-x: auto;
  }
}

.member-list {
  padding-top: 25px;

  .member-list-items {
    font-size: 14px;
    max-height: 300px;
    overflow-y: auto;
    /deep/ .ant-checkbox-group-item {
      display: block;
    }

    /deep/ .ant-space {
      margin-bottom: 5px;
    }

    .userName {
      max-width: 115px;
      font-size: 16px;
      color: #4D4D4D;
    }

    .mobile {
      max-width: 100px;
      color: #A7A7A7;
    }

    .companyName {
      max-width: 115px;
      color: #A7A7A7;
    }

    /deep/ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }

  }

  .member-list-items-checked {
    padding-top: 10px;

    .header {
      padding: 10px 0;
      display: flex;
      align-items: center;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      margin-bottom: 10px;

      .checked {
        font-size: 18px;
        color: #4D4D4D;
        margin-right: 5px;
      }
    }

    .list-item {
      //height: 36px;
      //line-height: 36px;
      overflow: hidden;
      justify-content: space-between;
      display: flex;
      margin-bottom: 5px;

      .userName {
        max-width: 115px;
        font-size: 16px;
        color: #4D4D4D;
      }

      .mobile {
        max-width: 95px;
        color: #A7A7A7;
      }

      .companyName {
        max-width: 115px;
        color: #A7A7A7;
      }

      .remove {
        cursor: pointer;
      }
    }
  }
}

.ant-space-item > .ant-avatar-image {
  vertical-align: text-bottom;
}

</style>
